import React from 'react';
import _ from 'lodash';

const azureBlobBaseUrl = 'https://sorra.blob.core.windows.net/';
const azureCdnBaseUrl = 'https://sorra.azureedge.net/';
const imageLoader = ({ src }) => {
  if (!src) {
    return '';
  }
  if (src.startsWith(azureBlobBaseUrl)) {
    return src.replace(azureBlobBaseUrl, azureCdnBaseUrl);
  } else if (_.includes(src, '://')) {
    return src;
  } else {
    return `${process.env.NEXT_PUBLIC_API_HOST}${src}`;
  }
};

const StrapiImg = (props) => {
  const { src, ...restProps } = props;
  const strapiSrc = imageLoader({ src });
  return <img src={strapiSrc} {...restProps} />;
};

export default StrapiImg;
