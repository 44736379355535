import _ from 'lodash';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Markdown from 'markdown-to-jsx';
import useSWR from 'swr';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import MaxWidthContainer from 'components/MaxWidthContainer';
import Menubar from 'components/Menubar';
import MobileTabBar from 'components/MobileTabBar';
import IndexBannerCarouselMobile from 'components/IndexBannerCarouselMobile';
import IndexCommentCarouselMobile from 'components/IndexCommentCarouselMobile';
import CommentPopup from 'components/CommentPopup';
import Footer from 'components/Footer';
import StrapiImg from 'components/StrapiImg';

import api, { swrFetcher } from 'utils/api';
import { swrFetcher as wpSwrFetcher } from 'utils/wpApi';

import { httpCall } from 'functions/httpAction';
import { MixpanelConfig } from 'utils/mixpanelConfig';

import { useAppLaunch } from 'contexts/AppLaunch';

import useRWD from 'hooks/useRWD';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Home = (props) => {
  const { seo } = props;

  const { isLg } = useRWD();

  const router = useRouter();

  const {
    categoryTree,
    secondCategories,
    searchSkinProblemKeywords,
    notifications,
    storeMap,
    productOriginMap,
    factorMap,
    reviewSourceMap,
    reviewTagMap,
  } = useAppLaunch();

  const carouselInterval = isLg ? 2000 : 1500;

  const { data: home, isLoading: isHomeLoading } = useSWR(
    '/api/v2/home',
    swrFetcher,
    {
      fallbackData: {
        new_reviews: [],
        promo_banners: [],
        promotions: [],
        sorra_featured: [],
        sorra_featured2: [],
        sorra_popular_categories: [],
        seo_question: '',
        seo_recommend: '',
      },
    }
  );

  const {
    promo_banners,
    promotions,
    new_reviews,
    sorra_featured,
    sorra_featured2,
    sorra_popular_categories,
    seo_question,
    seo_recommend,
  } = home;

  const promoBanners = _.filter(promo_banners, (b) => {
    return _.get(b, 'image');
  });

  const { data: activities, isLoading: isActivitiesLoading } = useSWR(
    '/api/v3/activities',
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const { data: miniBanners, isLoading: isMiniBannersLoading } = useSWR(
    '/api/v3/home/miniBanners',
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const { data: rewards, isLoading: isRewardsLoading } = useSWR(
    '/api/v3/home/rewards',
    swrFetcher,
    {
      fallbackData: [],
    }
  );

  const { data: posts, isLoading: isPostsLoading } = useSWR(
    'wp-json/wp/v2/posts?page=1&per_page=4',
    wpSwrFetcher,
    { fallbackData: [] }
  );

  const { data: postCategories, isLoading: isPostCategoriesLoading } = useSWR(
    'wp-json/wp/v2/categories?per_page=30',
    wpSwrFetcher,
    { fallbackData: [] }
  );

  const postCategoriesMap = _.keyBy(postCategories, 'id');

  const chunkMiniBanners = _.chunk(miniBanners, 4);
  const newActivities = _.take(activities, 3);
  const [activeMiniBannerIndex, setActiveMiniBannerIndex] = useState(0);

  // sorra featured
  const [sorraFeatured, setSorraFeatured] = useState([]);
  const [selectedSorraFeatured, setSelectedSorraFeatured] = useState(null);
  const [selectedSorraFeaturedProducts, setSelectedSorraFeaturedProducts] =
    useState([]);
  const [
    selectedMobileSorraFeaturedProducts,
    setSelectedMobileSorraFeaturedProducts,
  ] = useState([]);

  const chunkSorraFeaturedProducts = _.chunk(selectedSorraFeaturedProducts, 4);
  const chunkMobileSorraFeaturedProducts = _.chunk(
    selectedMobileSorraFeaturedProducts,
    2
  );

  // sorra featured 2
  const [sorraFeatured2, setSorraFeatured2] = useState([]);
  const [selectedSorraFeatured2, setSelectedSorraFeatured2] = useState(null);
  const [selectedSorraFeatured2Products, setSelectedSorraFeatured2Products] =
    useState([]);
  const [
    selectedMobileSorraFeatured2Products,
    setSelectedMobileSorraFeatured2Products,
  ] = useState([]);

  const chunkSorraFeatured2Products = _.chunk(
    selectedSorraFeatured2Products,
    4
  );
  const chunkMobileSorraFeatured2Products = _.chunk(
    selectedMobileSorraFeatured2Products,
    2
  );

  useEffect(() => {
    if (sorraFeatured.length !== sorra_featured.length) {
      setSorraFeatured(sorra_featured);
      setSelectedSorraFeatured(0);
    }
    if (sorraFeatured2.length !== sorra_featured2.length) {
      setSorraFeatured2(sorra_featured2);
      setSelectedSorraFeatured2(0);
    }
  }, [sorra_featured, sorra_featured2]);

  useEffect(() => {
    if (sorraFeatured.length > 0) {
      const target = sorraFeatured[selectedSorraFeatured];
      if (target) {
        const products = target.products;
        const banner = target.sorra_featured_banner;
        if (banner) {
          banner.isBanner = true;
          const [a, b, c, ...rest] = products;
          const newProducts = _.compact([a, b, c, banner, ...rest]);
          setSelectedSorraFeaturedProducts(newProducts);
          setSelectedMobileSorraFeaturedProducts([banner, ...products]);
          return;
        } else {
          setSelectedSorraFeaturedProducts(products);
          setSelectedMobileSorraFeaturedProducts(products);
          return;
        }
      }
    }
    setSelectedSorraFeaturedProducts([]);
  }, [sorraFeatured, selectedSorraFeatured]);

  useEffect(() => {
    if (sorraFeatured2.length > 0) {
      const target = sorraFeatured2[selectedSorraFeatured2];
      if (target) {
        const products = target.products;
        const banner = target.sorra_featured_banner;
        if (banner) {
          banner.isBanner = true;
          const [a, b, c, ...rest] = products;
          const newProducts = _.compact([a, b, c, banner, ...rest]);
          setSelectedSorraFeatured2Products(newProducts);
          setSelectedMobileSorraFeatured2Products([banner, ...products]);
          return;
        } else {
          setSelectedSorraFeatured2Products(products);
          setSelectedMobileSorraFeatured2Products(products);
          return;
        }
      }
    }
    setSelectedSorraFeatured2Products([]);
  }, [sorraFeatured2, selectedSorraFeatured2]);

  const [searchBackgroundDisplayState, setSearchBackgroundDisplayState] =
    useState(false);
  const [searchProductInputFocusState, setSearchProductInputFocusState] =
    useState(false);
  const [searchCategoryInputFocusState, setSearchCategoryInputFocusState] =
    useState(false);
  const [searchKeywordInputFocusState, setSearchKeywordInputFocusState] =
    useState(false);

  const [searchBrandKeywordState, setSearchBrandKeywordState] = useState('');
  const [searchProductKeywordState, setSearchProductKeywordState] =
    useState('');
  const [searchCategoryKeywordState, setSearchCategoryKeywordState] =
    useState('');
  const [searchKeywordKeywordState, setSearchKeywordKeywordState] =
    useState('');

  const [searchCategoryIdState, setSearchCategoryIdState] = useState('');

  const [arrSearchProductsState, setArrSearchProductsState] = useState([]);
  const [arrSearchBrandtsState, setArrSearchBrandState] = useState([]);
  const [
    arrSearchCategoryMobileCollapseState,
    setArrSearchCategoryMobileCollapseState,
  ] = useState([]);

  const [blockSearchProductState, setBlockSearchProductState] = useState('');
  const [blockSearchCategoryState, setBlockSearchCategoryState] = useState('');
  const [blockSearchKeywordState, setBlockSearchKeywordState] = useState('');

  const [blockSearchProductMobileState, setBlockSearchProductMobileState] =
    useState('');
  const [blockSearchCategoryMobileState, setBlockSearchCategoryMobileState] =
    useState('');
  const [blockSearchKeywordMobileState, setBlockSearchKeywordMobileState] =
    useState('');

  const [scrollPositionState, setScrollPositionState] = useState(0);

  const [bottomSuggestionsExpanded, setBottomSuggestionsExpanded] =
    useState(false);
  const [bottomFaqExpanded, setBottomFaqExpanded] = useState(false);

  const [isCommentModalShow, setIsCommentModalShow] = useState(false);

  async function fetchSearch() {
    try {
      const [success, error, statusCode, data] = await httpCall(
        'get',
        process.env.NEXT_PUBLIC_API_HOST +
          '/api/v2/search?keyword=' +
          searchProductKeywordState,
        null,
        null
      );

      if (success == true) {
        if (data.brands != null) {
          var arrBrandTmp = [];

          data.brands.forEach((element) => {
            arrBrandTmp.push(element);
          });

          setArrSearchBrandState(arrBrandTmp);
        }

        if (data.products != null) {
          var arrProductTmp = [];

          data.products.forEach((element) => {
            arrProductTmp.push(element);
          });

          setArrSearchProductsState(arrProductTmp);
        }
      } else {
        console.log('api error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  function loadBlockSearchProduct() {
    var blockSearchProductTmp = [];

    if (
      arrSearchProductsState.length == 0 &&
      arrSearchBrandtsState.length == 0
    ) {
      setBlockSearchProductState(
        <div className="flex h-full w-full items-center justify-center">
          <div className="space-y-3">
            <img className="m-[auto]" src="/svgs/search-empty.svg"></img>
            <div className="font-medium text-sorra-gray-800">沒有搜索結果</div>
          </div>
        </div>
      );

      setBlockSearchProductMobileState(
        <div className="flex h-[190px] w-full items-center justify-center">
          <div className="space-y-3">
            <img className="m-[auto]" src="/svgs/search-empty.svg"></img>
            <div className="font-medium text-sorra-gray-800">沒有搜索結果</div>
          </div>
        </div>
      );
    } else {
      arrSearchProductsState.forEach(function (element, index) {
        var imageURL = '';

        if (element.images.length > 0) {
          imageURL = element.images[0].url;
        } else {
          imageURL = 'images/placeholders/brand.png';
        }

        if (index < 2) {
          blockSearchProductTmp.push(
            <div
              key={element.id}
              className="flex h-[46px] cursor-pointer space-x-2"
              onClick={() => {
                setSearchBrandKeywordState(element.brand.name);
                setSearchProductKeywordState(element.name);
                hideSearchHint();
              }}
            >
              <img src={imageURL} className="h-[46px] w-[40px]"></img>
              <div className="h-full w-full">
                <div className="text-sm font-medium text-sorra-gray-900">
                  {element.name}
                </div>
                <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                  {element.brand.name}
                </div>
              </div>
            </div>
          );
        }
      });

      blockSearchProductTmp.push(
        <div className="border-t-2 border-gray-100"></div>
      );

      arrSearchBrandtsState.forEach(function (element, index) {
        var imageURL = '';

        if (element.image != null) {
          imageURL = element.image.url;
        } else {
          imageURL = 'images/placeholders/brand.png';
        }

        if (index < 2) {
          blockSearchProductTmp.push(
            <div
              key={element.id}
              className="flex h-[46px] cursor-pointer space-x-2"
              onClick={() => {
                setSearchBrandKeywordState('');
                setSearchProductKeywordState(element.name);
                hideSearchHint();
              }}
            >
              <img
                src={imageURL}
                className="h-[46px] w-[40px] object-scale-down"
              ></img>
              <div className="h-full w-full">
                <div className="text-sm font-medium text-sorra-gray-900">
                  {element.name}
                </div>
                <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                  {element.name}
                </div>
              </div>
            </div>
          );
        }
      });

      setBlockSearchProductState(blockSearchProductTmp);
      setBlockSearchProductMobileState(blockSearchProductTmp);
    }
  }

  function loadBlockSearchCategory() {
    var arrCategoryStateFiltered = [];

    if (searchCategoryKeywordState == '') {
      arrCategoryStateFiltered = categoryTree; //arrCategoryState;
    } else {
      categoryTree.forEach((element) => {
        var categoryTmp = JSON.parse(JSON.stringify(element));
        var arrSubCategoryStateFiltered = [];

        element.second_categories.forEach((subelement) => {
          if (subelement.name.includes(searchCategoryKeywordState)) {
            arrSubCategoryStateFiltered.push(subelement);
          }
        });

        categoryTmp.second_categories = arrSubCategoryStateFiltered;

        arrCategoryStateFiltered.push(categoryTmp);
      });
    }

    var blockSearchCategoryTmp = [];
    var blockSearchCategoryMobileTmp = [];

    arrCategoryStateFiltered.forEach(function (element, index) {
      var blockSearchSubcategoryTmp = [];

      element.second_categories.forEach((subelement) => {
        blockSearchSubcategoryTmp.push(
          <div
            key={subelement.id}
            className="flex cursor-pointer gap-x-0.5 hover:ml-1"
            onClick={() => {
              setSearchCategoryKeywordState(subelement.name);
              setSearchCategoryIdState(subelement.id);
              hideSearchHint();
            }}
          >
            <img
              src="/svgs/index-partnership-arrow.svg"
              className="mb-[auto] mt-[2px]"
            ></img>
            <div className="text-sm font-medium text-sorra-gray-900">
              {subelement.name}
            </div>
          </div>
        );
      });

      blockSearchCategoryTmp.push(
        <div key={element.id} className="space-y-2">
          <div className="text-sorra-gray-900">{element.name}</div>
          {blockSearchSubcategoryTmp}
        </div>
      );

      blockSearchCategoryMobileTmp.push(
        <div key={element.id} className="border-b-2 border-gray-100">
          <div
            className="flex cursor-pointer gap-x-4 p-4 text-sm font-medium text-sorra-gray-800"
            onClick={() => pressSearchCategoryMobileExpands(index)}
          >
            <div className="grow">{element.name}</div>
            <img
              src={
                arrSearchCategoryMobileCollapseState.includes(index)
                  ? '/svgs/chevron-down.svg'
                  : '/svgs/chevron-up.svg'
              }
            ></img>
          </div>
          <div
            className={
              'grid grid-cols-2 gap-y-4 px-4 pb-4' +
              (blockSearchSubcategoryTmp.length == 0 ||
              arrSearchCategoryMobileCollapseState.includes(index)
                ? ' hidden'
                : '')
            }
          >
            {blockSearchSubcategoryTmp}
          </div>
        </div>
      );
    });

    setBlockSearchCategoryState(
      <div className="flex grid max-h-full w-full grid-cols-5 gap-x-4 gap-y-8 overflow-y-scroll px-2 pb-6">
        {blockSearchCategoryTmp}
      </div>
    );

    setBlockSearchCategoryMobileState(
      <div className="w-[calc(100% + 32px)] mx-[-16px] mt-[-32px] max-h-full overflow-y-scroll">
        {blockSearchCategoryMobileTmp}
      </div>
    );
  }

  function loadBlockSearchKeyword() {
    var blockSearchKeywordTmp = [];

    searchSkinProblemKeywords.forEach((element) => {
      blockSearchKeywordTmp.push(
        <div
          key={element.id}
          className="flex cursor-pointer gap-x-0.5 hover:ml-1"
          onClick={() => {
            setSearchKeywordKeywordState(element.name);
            hideSearchHint();
          }}
        >
          <img
            src="/svgs/index-partnership-arrow.svg"
            className="mb-[auto] mt-[2px]"
          ></img>
          <div className="text-sm font-medium text-sorra-gray-900">
            {element.name}
          </div>
        </div>
      );
    });

    setBlockSearchKeywordState(
      <>
        <div className="flex grid max-h-full w-full grid-cols-2 gap-x-4 gap-y-2 overflow-y-scroll px-2">
          <div className="col-span-2 text-sorra-gray-900">熱門搜尋</div>
          {blockSearchKeywordTmp}
        </div>
      </>
    );

    setBlockSearchKeywordMobileState(
      <>
        <div className="flex grid max-h-full w-full grid-cols-2 gap-x-4 gap-y-2 px-2">
          <div className="col-span-2 text-sorra-gray-900">熱門搜尋</div>
          {blockSearchKeywordTmp}
        </div>
      </>
    );
  }

  function pressSearchProductInputFocus(focused) {
    if (focused == true) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }

    hideSearchHint();

    setSearchBackgroundDisplayState(focused);
    setSearchProductInputFocusState(focused);

    fetchSearch();
  }

  function pressSearchCategoryInputFocus(focused) {
    if (focused == true) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }

    hideSearchHint();

    setSearchBackgroundDisplayState(focused);
    setSearchCategoryInputFocusState(focused);
  }

  function pressSearchKeywordInputFocus(focused) {
    if (focused == true) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }

    hideSearchHint();

    setSearchBackgroundDisplayState(focused);
    setSearchKeywordInputFocusState(focused);
  }

  function pressSearchMobileInputFocus(focused) {
    hideSearchHint();
    setSearchBackgroundDisplayState(focused);
  }

  function pressSearchCategoryMobileExpands(index) {
    var arrSearchCategoryMobileCollapseTmp =
      arrSearchCategoryMobileCollapseState.concat();

    if (arrSearchCategoryMobileCollapseTmp.includes(index)) {
      var index = arrSearchCategoryMobileCollapseTmp.indexOf(index);
      if (index > -1) {
        arrSearchCategoryMobileCollapseTmp.splice(index, 1);
      }
    } else {
      arrSearchCategoryMobileCollapseTmp.push(index);
    }

    setArrSearchCategoryMobileCollapseState(arrSearchCategoryMobileCollapseTmp);
  }

  function hideSearchHint() {
    setSearchProductInputFocusState(false);
    setSearchCategoryInputFocusState(false);
    setSearchKeywordInputFocusState(false);
  }

  function pressSearch() {
    var brandEncoded = '';
    var productEncoded = '';
    var categoryIdEncoded = '';
    var categoryEncoded = '';
    var keywordEncoded = '';

    var queryString = '';
    var logQuery = '';

    brandEncoded =
      'brand=' +
      encodeURIComponent(
        searchBrandKeywordState == null ? '' : searchBrandKeywordState
      );
    productEncoded =
      'product=' +
      encodeURIComponent(
        searchProductKeywordState == null ? '' : searchProductKeywordState
      );
    categoryIdEncoded =
      'categoryID=' + encodeURIComponent(searchCategoryIdState);

    if (searchCategoryIdState != '') {
      categoryEncoded =
        'category=' + encodeURIComponent(searchCategoryKeywordState);
    } else {
      categoryEncoded = 'category=';
    }

    keywordEncoded =
      'keyword=' +
      encodeURIComponent(
        searchKeywordKeywordState == null ? '' : searchKeywordKeywordState
      );

    queryString =
      brandEncoded +
      '&' +
      productEncoded +
      '&' +
      categoryEncoded +
      '&' +
      categoryIdEncoded +
      '&' +
      keywordEncoded;

    logQuery +=
      searchBrandKeywordState != '' && searchBrandKeywordState != 'null'
        ? searchBrandKeywordState + ' '
        : '';
    logQuery += searchProductKeywordState;

    if (logQuery != '' && searchCategoryKeywordState != '') {
      logQuery += ' • ';
    }

    logQuery += searchCategoryKeywordState;

    if (logQuery != '' && searchKeywordKeywordState != '') {
      logQuery += ' • ';
    }

    logQuery += searchKeywordKeywordState;

    MixpanelConfig.track('search_performed', { search: logQuery });

    // router.push('/search?' + queryString);
    window.location.href = '/search?' + queryString;
  }

  useEffect(() => {
    const handleScroll = (e) => {
      var scrollTop = e.target.scrollTop;

      var index_header = document.getElementById('index_header');
      var index_headerBox = index_header.getBoundingClientRect();
      var index_headerHeight = index_headerBox.height;

      setScrollPositionState(scrollTop / (index_headerHeight - 200));
    };

    if (document.querySelector('#app-wrapper')) {
      document
        .querySelector('#app-wrapper')
        .addEventListener('scroll', handleScroll);
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const search = queryParameters.get('search');
    const product = queryParameters.get('product');
    const category = queryParameters.get('category');
    const categoryID = queryParameters.get('categoryID');
    const keyword = queryParameters.get('keyword');
    const showComment = queryParameters.get('showComment');

    if (search != null) {
      pressSearchProductInputFocus(true);
      setSearchBrandKeywordState('');
      setSearchProductKeywordState(product);
      setSearchCategoryKeywordState(category);
      setSearchCategoryIdState(categoryID);
      setSearchKeywordKeywordState(keyword);
    }

    if (showComment == 'true') {
      setIsCommentModalShow(true);
    }

    window.history.pushState({}, document.title, '/');

    return () => {
      if (document.querySelector('#app-wrapper')) {
        document
          .querySelector('#app-wrapper')
          .removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    loadBlockSearchCategory();
  }, [
    categoryTree,
    searchCategoryKeywordState,
    arrSearchCategoryMobileCollapseState,
  ]);

  useEffect(() => {
    fetchSearch();
  }, [searchProductKeywordState]);

  useEffect(() => {
    loadBlockSearchProduct();
  }, [arrSearchProductsState, arrSearchBrandtsState]);

  useEffect(() => {
    loadBlockSearchKeyword();
  }, [searchSkinProblemKeywords]);

  // return (
  //   <div>
  //     <NextSeo title={seo.title} description={seo.description} />

  //     <main>
  //       <Menubar
  //         props={{
  //           homeExtend: false,
  //           scrollPosition: scrollPositionState,
  //           sourceUI: 'Home',
  //         }}
  //         pressSearch={() => {
  //           pressSearchProductInputFocus(true);
  //         }}
  //       />
  //       <p className="pt-60">aaaaaa</p>
  //     </main>
  //   </div>
  // );

  return (
    <div>
      <NextSeo title={seo.title} description={seo.description} />

      <main>
        <Menubar
          props={{
            homeExtend: true,
            scrollPosition: scrollPositionState,
            sourceUI: 'Home',
          }}
          pressSearch={() => {
            pressSearchProductInputFocus(true);
          }}
        />

        <div className="globals-centerContentsMargin disabledTop">
          <div
            className={
              'index-header-search-background hidden lg:block' +
              ((searchBackgroundDisplayState == true &&
                searchProductKeywordState == '' &&
                searchCategoryKeywordState == '' &&
                searchKeywordKeywordState == '') ||
              searchProductInputFocusState == true ||
              searchCategoryInputFocusState == true ||
              searchKeywordInputFocusState == true
                ? ''
                : ' transpirant')
            }
            onClick={() => {
              pressSearchProductInputFocus(false);
              pressSearchCategoryInputFocus(false);
              pressSearchKeywordInputFocus(false);
            }}
          ></div>

          <div
            className={
              "index-header relative bg-[url('/images/bg-header-new_mobile.jpg')] bg-cover bg-center lg:bg-[url('/images/bg-header-new.jpeg')]" +
              (notifications?.length > 0 ? ' withNoti' : '')
            }
            id="index_header"
          >
            <MaxWidthContainer
              className={
                'index-header-maxWidthContainer relative flex h-full items-start justify-between' +
                (notifications?.length > 0 ? ' withNoti' : '')
              }
            >
              <div className="index-header-captions relative flex w-full grid-rows-1 flex-col items-center justify-center gap-y-2 lg:gap-y-4">
                <p className="text-center text-2xl font-bold lg:text-5xl">
                  Buy Less, Choose Well.
                </p>
                <p className="text-center text-base font-medium lg:text-2xl">
                  最真實美妝評論平台
                </p>
              </div>

              {/* search bar */}
              <div
                className={
                  'index-header-search-frame absolute inset-x-0 bottom-0 z-20 z-[50] hidden px-30 lg:flex' +
                  (scrollPositionState > 1.0 ? ' transpirant' : '')
                }
              >
                <div className="relative flex w-full translate-y-1/2 flex-row items-center rounded-lg bg-white p-4 shadow-lg">
                  <div className="flex h-full grow items-stretch">
                    {/* product */}
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <Image
                          src="/svgs/search.svg"
                          height={24}
                          width={24}
                          alt="search"
                        />
                      </div>
                      <input
                        type="text"
                        value={searchProductKeywordState}
                        className="block h-14 w-full border-none py-1.5 pl-15 pr-15 text-base text-sorra-gray-900 outline-none placeholder:text-gray-500"
                        placeholder="美妝產品 / 品牌"
                        onFocus={() => pressSearchProductInputFocus(true)}
                        // onBlur={() => pressSearchProductInputFocus(false)}
                        onChange={(event) => {
                          setSearchBrandKeywordState('');
                          setSearchProductKeywordState(event.target.value);
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchBrandKeywordState('');
                          setSearchProductKeywordState('');
                        }}
                      >
                        {searchProductKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                      <div
                        className={
                          'absolute left-[-20px] top-[100px] z-[50] h-[297px] w-[575px] rounded-lg bg-white px-4 py-6' +
                          (searchProductInputFocusState == true
                            ? ''
                            : ' hidden')
                        }
                      >
                        <div className="absolute left-[40px] top-[-8px] h-[20px] w-[20px] rotate-[45deg] bg-white"></div>

                        <div className="h-full w-full space-y-4">
                          {blockSearchProductState}
                        </div>
                      </div>
                    </div>
                    <div className="w-px bg-gray-100" />
                    {/* category */}
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <Image
                          src="/svgs/grid.svg"
                          height={24}
                          width={24}
                          alt="search"
                        />
                      </div>
                      <input
                        type="text"
                        value={searchCategoryKeywordState}
                        className="block h-14 w-full border-none py-1.5 pl-15 pr-15 text-base text-sorra-gray-900 outline-none placeholder:text-gray-500"
                        placeholder="產品類型"
                        onFocus={() => pressSearchCategoryInputFocus(true)}
                        // onBlur={() => setIsCategoryInputFocus(false)}
                        onChange={(event) => {
                          setSearchCategoryKeywordState(event.target.value);
                          setSearchCategoryIdState('');
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchCategoryKeywordState('');
                          setSearchCategoryIdState('');
                        }}
                      >
                        {searchCategoryKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                      <div
                        className={
                          'absolute left-[-300px] top-[100px] z-[50] h-[345px] w-[1024px] rounded-lg bg-white px-4 pt-6' +
                          (searchCategoryInputFocusState == true
                            ? ''
                            : ' hidden')
                        }
                      >
                        <div className="absolute left-[320px] top-[-8px] h-[20px] w-[20px] rotate-[45deg] bg-white"></div>

                        <div className="h-full w-full space-y-4">
                          {blockSearchCategoryState}
                        </div>
                      </div>
                    </div>
                    <div className="w-px bg-gray-100" />
                    {/* factor */}
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <Image
                          src="/svgs/sparkles.svg"
                          height={24}
                          width={24}
                          alt="search"
                        />
                      </div>
                      <input
                        type="text"
                        value={searchKeywordKeywordState}
                        className="block h-14 w-full border-none py-1.5 pl-15 pr-15 text-base text-sorra-gray-900 outline-none placeholder:text-gray-500"
                        placeholder="皮膚問題 / 功效 / 關鍵字"
                        onFocus={() => pressSearchKeywordInputFocus(true)}
                        // onBlur={() => setIsFactorInputFocus(false)}
                        onChange={(event) =>
                          setSearchKeywordKeywordState(event.target.value)
                        }
                      />
                      <button
                        className={
                          'absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink' +
                          (searchKeywordKeywordState == '' ? ' hidden' : '')
                        }
                        onClick={() => setSearchKeywordKeywordState('')}
                      >
                        <Image
                          src="/svgs/clear.svg"
                          alt="clear"
                          width={14}
                          height={14}
                        />
                      </button>
                      <div
                        className={
                          'absolute left-[-20px] top-[100px] z-[50] h-[180px] w-[450px] rounded-lg bg-white px-4 py-6' +
                          (searchKeywordInputFocusState == true
                            ? ''
                            : ' hidden')
                        }
                      >
                        <div className="absolute left-[40px] top-[-8px] h-[20px] w-[20px] rotate-[45deg] bg-white"></div>

                        <div className="h-full w-full space-y-4">
                          {blockSearchKeywordState}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="flex w-[128px] items-center justify-center gap-x-3 rounded-lg bg-sorra-pink px-6 py-3"
                    onClick={() => pressSearch()}
                  >
                    <Image
                      src="/svgs/search-w.svg"
                      height={20}
                      width={21}
                      alt="search"
                    />
                    <p className="text-sm text-white">搜索</p>
                  </button>
                  <div className="bubble-wrapper absolute inset-x-0 bottom-0 drop-shadow-md">
                    {/* {isProductInputFocus && (
                                        <div className="product-bubble absolute left-0 top-6 h-20 w-1/3 rounded-lg bg-white" />
                                    )}
                                    {isCategoryInputFocus && (
                                        <div className="category-bubble absolute inset-x-0 top-6 h-20 w-full rounded-lg bg-white" />
                                    )}
                                    {isFactorInputFocus && (
                                        <div className="factor-bubble absolute right-0 top-6 h-20 w-2/5 rounded-lg bg-white" />
                                    )} */}
                  </div>
                </div>
              </div>

              <div
                className={
                  'index-header-search-mobile-frame absolute inset-x-0 bottom-4 z-20 flex lg:hidden' +
                  (scrollPositionState > 1.0 ? ' transpirant' : '')
                }
              >
                <div className="mx-4 w-full">
                  <div className="index-header-search-mobile-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <img src="/svgs/search.svg"></img>
                  </div>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-0 index-header-search-mobile block w-full rounded-lg border-none p-2.5 pl-10 text-sm text-sorra-gray-900 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="搜尋美妝產品・話題"
                    onFocus={(event) => {
                      pressSearchMobileInputFocus(true);
                      event.target.blur();
                    }}
                  />
                </div>
              </div>

              <div
                className={
                  'index-header-search-mobile-overlay fixed left-0 top-0 z-[50] flex h-full w-full flex-col' +
                  (searchBackgroundDisplayState == true
                    ? ' block lg:hidden'
                    : ' hidden')
                }
              >
                <div className="index-header-search-mobile-overlay-title relative flex w-full text-lg font-medium text-sorra-gray-900">
                  <button
                    className="border-text-gray-100 absolute left-0 mb-2 mr-2 h-[48px] w-[48px] rounded-lg border p-[14px] text-center text-sm font-medium text-gray-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
                    onClick={() => {
                      pressSearchProductInputFocus(false);
                      pressSearchCategoryInputFocus(false);
                      pressSearchKeywordInputFocus(false);
                    }}
                  >
                    <img
                      src="/svgs/modal-close.svg"
                      className="h-[20px] w-[20px]"
                    ></img>
                  </button>
                  <div className="h-[48px]">搜尋</div>
                </div>
                <div className="grow overflow-x-hidden overflow-y-scroll">
                  <div className="space-y-2 py-[24px]">
                    <div className="relative h-[48px] w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                        <img src="/svgs/search.svg"></img>
                      </div>
                      <input
                        type="text"
                        id=""
                        className="bg-gray-0 block h-full w-full rounded-lg border border-gray-300 p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0"
                        placeholder="美妝產品 / 品牌"
                        value={searchProductKeywordState}
                        onFocus={() => pressSearchProductInputFocus(true)}
                        onChange={(event) => {
                          setSearchBrandKeywordState;
                          setSearchProductKeywordState(event.target.value);
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchBrandKeywordState('');
                          setSearchProductKeywordState('');
                        }}
                      >
                        {searchProductKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                    </div>

                    <div className="relative h-[48px] w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                        <img src="/svgs/sparkles.svg"></img>
                      </div>
                      <input
                        type="text"
                        id=""
                        className="bg-gray-0 block h-full w-full rounded-lg border border-gray-300 p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0"
                        placeholder="產品類型"
                        value={searchCategoryKeywordState}
                        onFocus={() => pressSearchCategoryInputFocus(true)}
                        onChange={(event) => {
                          setSearchCategoryKeywordState(event.target.value);
                          setSearchCategoryIdState('');
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchCategoryKeywordState('');
                          setSearchCategoryIdState('');
                        }}
                      >
                        {searchCategoryKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                    </div>

                    <div className="relative h-[48px] w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                        <img src="/svgs/grid.svg"></img>
                      </div>
                      <input
                        type="text"
                        id=""
                        className="bg-gray-0 block h-full w-full rounded-lg border border-gray-300 p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0"
                        placeholder="皮膚問題 / 功效 / 關鍵字"
                        value={searchKeywordKeywordState}
                        onFocus={() => pressSearchKeywordInputFocus(true)}
                        onChange={(event) =>
                          setSearchKeywordKeywordState(event.target.value)
                        }
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => setSearchKeywordKeywordState('')}
                      >
                        {searchKeywordKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="ml-[-16px] w-[200%] border-t-2 border-gray-100"></div>
                  <div className="py-[32px]">
                    <div
                      className={
                        'h-full w-full space-y-4' +
                        (searchProductInputFocusState == true ? '' : ' hidden')
                      }
                    >
                      {blockSearchProductMobileState}
                    </div>
                    <div
                      className={
                        'h-full w-full' +
                        (searchCategoryInputFocusState == true ? '' : ' hidden')
                      }
                    >
                      {blockSearchCategoryMobileState}
                    </div>
                    <div
                      className={
                        'h-full w-full' +
                        (searchKeywordInputFocusState == true ? '' : ' hidden')
                      }
                    >
                      {blockSearchKeywordMobileState}
                    </div>
                  </div>
                </div>
                <div className="mt-[32px] h-[48px]">
                  <button
                    className="flex h-full w-full items-center justify-center gap-x-3 rounded-lg bg-sorra-pink px-8 py-3"
                    onClick={() => pressSearch()}
                  >
                    <Image
                      src="/svgs/search-w.svg"
                      height={20}
                      width={21}
                      alt="search"
                    />
                    <p className="text-sm text-white">搜索</p>
                  </button>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          {/* promoBanners */}
          <div
            className={
              'pt-6 lg:pt-[78px]' +
              (promoBanners.length == 0 && isHomeLoading == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer>
              <div className="relative block px-4">
                <div className="mx-auto hidden h-[350px] w-[1024px] lg:block">
                  {isHomeLoading ? (
                    <div className="h-full w-full">
                      <Skeleton className="h-full w-full" />
                    </div>
                  ) : (
                    <IndexBannerCarouselMobile
                      className="home-banner h-full w-full overflow-visible"
                      showArrows={false}
                    >
                      {promoBanners.map((element) => {
                        var backgroundImageURL = '';
                        var hrefURL = '';
                        var hrefNewTab = false;

                        if (element.image != null) {
                          backgroundImageURL = element.image.url;
                        }

                        if (element.url != null) {
                          hrefURL = element.url;
                        }

                        hrefNewTab = element.new_tab;

                        return (
                          <a
                            key={element.id}
                            href={!hrefURL ? '#' : hrefURL}
                            onClick={() => {
                              MixpanelConfig.track('Click', {
                                Component: 'Home Page Ad',
                                'Ad Name': element.name || null,
                                'Ad URL': element.url || null,
                              });
                            }}
                            target={hrefNewTab == true ? '_blank' : ''}
                          >
                            <div
                              className={
                                'index-banner-item-mobile relative h-full w-full px-6 py-8'
                              }
                              style={{
                                backgroundImage:
                                  'url(' + backgroundImageURL + ')',
                              }}
                            >
                              {element.id === 87 && (
                                <img
                                  className="h-px w-px"
                                  src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32821884.407904241;dc_trk_aid=599933411;dc_trk_cid=225687372;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                                />
                              )}
                            </div>
                          </a>
                        );
                      })}
                    </IndexBannerCarouselMobile>
                  )}
                </div>
                <div className="aspect-[343/193] w-full lg:hidden">
                  {isHomeLoading ? (
                    <div className="home-banner h-full w-full overflow-visible">
                      <div
                        className={
                          'index-banner-item-mobile relative h-full w-full'
                        }
                      >
                        {<Skeleton width={'100%'} height={'100%'} />}
                      </div>
                    </div>
                  ) : (
                    <IndexBannerCarouselMobile
                      className="home-banner h-full w-full overflow-visible"
                      showArrows={false}
                    >
                      {promoBanners.map((element) => {
                        var backgroundImageURL = '';
                        var hrefURL = '';
                        var hrefNewTab = false;

                        if (element.mobile_image != null) {
                          backgroundImageURL = element.mobile_image.url;
                        } else if (element.image != null) {
                          backgroundImageURL = element.image.url;
                        }

                        if (element.url != null) {
                          hrefURL = element.url;
                        }

                        hrefNewTab = element.new_tab;

                        return (
                          <a
                            key={element.id}
                            href={!hrefURL ? '#' : hrefURL}
                            onClick={() => {
                              MixpanelConfig.track('Click', {
                                Component: 'Home Page Ad',
                                'Ad Name': element.name || null,
                                'Ad URL': element.url || null,
                              });
                            }}
                            target={hrefNewTab == true ? '_blank' : ''}
                          >
                            <div
                              className={
                                'index-banner-item-mobile relative h-full w-full px-6 py-8'
                              }
                              style={{
                                backgroundImage:
                                  'url(' + backgroundImageURL + ')',
                              }}
                            >
                              {element.id === 87 && (
                                <img
                                  className="h-px w-px"
                                  src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32821884.407904241;dc_trk_aid=599933411;dc_trk_cid=225687372;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                                />
                              )}
                            </div>
                          </a>
                        );
                      })}
                    </IndexBannerCarouselMobile>
                  )}
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          {/* min banners */}
          <div
            className={
              'pt-12 lg:pt-20' +
              (miniBanners.length == 0 && isHomeLoading == false
                ? ' hidden'
                : '')
            }
          >
            {(isMiniBannersLoading || miniBanners.length > 0) && (
              <MaxWidthContainer className="px-4">
                <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                  Sorra 精選
                </div>
                <div className="relative -mx-4 flex overflow-hidden pb-6 pr-20 pt-4 lg:hidden">
                  {isMiniBannersLoading ? (
                    <div className="flex h-[130px] w-full gap-x-2.5 overflow-visible pl-4">
                      {[0, 1].map((i) => {
                        return (
                          <Skeleton
                            key={i}
                            className="aspect-[306/170] h-full rounded-lg"
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mobile-mini-banner-carousel relative w-full pl-4">
                      <Carousel
                        swipeable={true}
                        emulateTouch={true}
                        autoPlay={true}
                        infiniteLoop={miniBanners.length > 1}
                        interval={carouselInterval}
                        stopOnHover={true}
                        showIndicators={false}
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        {miniBanners.map((banner) => {
                          const url = _.get(banner, 'image.url', '');
                          return (
                            <div key={banner.id} className="pr-2.5">
                              <div
                                className="aspect-[306/170] cursor-pointer overflow-hidden rounded-lg"
                                onClick={() => {
                                  if (banner.url) {
                                    window.open(
                                      banner.url,
                                      banner.new_tab ? '_blank' : ''
                                    );
                                  }
                                }}
                              >
                                <StrapiImg
                                  src={url}
                                  className="h-full w-full object-cover"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  )}
                </div>
                <div className="mini-banner-carousel relative hidden pb-10 pt-7 lg:block">
                  {isMiniBannersLoading ? (
                    <div className="grid grid-cols-4 gap-x-4">
                      {[0, 1, 2, 3].map((i) => {
                        return (
                          <Skeleton
                            key={i}
                            className="aspect-[306/170] rounded-lg"
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <Carousel
                      swipeable={true}
                      emulateTouch={true}
                      autoPlay={true}
                      infiniteLoop={chunkMiniBanners.length > 1}
                      interval={carouselInterval}
                      stopOnHover={true}
                      showIndicators={false}
                      showStatus={false}
                      showThumbs={false}
                      onChange={setActiveMiniBannerIndex}
                      renderArrowPrev={(clickHandler, hasPrev, labelPrev) => {
                        return (
                          activeMiniBannerIndex !== 0 && (
                            <button
                              onClick={clickHandler}
                              className="absolute -left-6 top-1/2 z-10 h-10 w-10 -translate-x-full -translate-y-1/2 rounded-full"
                              style={{
                                boxShadow:
                                  '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              <img
                                className="p-2.5"
                                src="/svgs/index-horizontalScroll-arrowLeft.svg"
                              ></img>
                            </button>
                          )
                        );
                      }}
                      renderArrowNext={(clickHandler, hasNext, labelNext) => {
                        return (
                          activeMiniBannerIndex !==
                            chunkMiniBanners.length - 1 && (
                            <button
                              onClick={clickHandler}
                              className="absolute -right-6 top-1/2 z-10 h-10 w-10 -translate-y-1/2 translate-x-full rounded-full"
                              style={{
                                boxShadow:
                                  '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              <img
                                className="p-2.5"
                                src="/svgs/index-horizontalScroll-arrowRight.svg"
                              ></img>
                            </button>
                          )
                        );
                      }}
                    >
                      {chunkMiniBanners.map((chunk, index) => {
                        return (
                          <div key={index} className="grid grid-cols-4 gap-x-4">
                            {chunk.map((banner) => {
                              const url = _.get(banner, 'image.url', '');
                              return (
                                <div
                                  key={banner.id}
                                  className="aspect-[306/170] cursor-pointer overflow-hidden rounded-lg"
                                  onClick={() => {
                                    if (banner.url) {
                                      window.open(
                                        banner.url,
                                        banner.new_tab ? '_blank' : ''
                                      );
                                    }
                                  }}
                                >
                                  <StrapiImg
                                    src={url}
                                    className="h-full w-full object-cover"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Carousel>
                  )}
                </div>
              </MaxWidthContainer>
            )}

            {/* sorra featured */}
            {/*  */}
            {(sorraFeatured.length > 0 || isHomeLoading) && (
              <div>
                <MaxWidthContainer className="px-4 lg:px-0">
                  <div className="no-scrollbar mt-6 flex w-full flex-row gap-x-8 overflow-x-auto">
                    {sorraFeatured.map((sf2, idx) => {
                      return (
                        <div
                          key={sf2.id}
                          className="flex shrink-0 cursor-pointer flex-col gap-y-4"
                          onClick={() => {
                            setSelectedSorraFeatured(idx);
                          }}
                        >
                          <p
                            className={classnames(
                              'text-base lg:text-xl',
                              idx === selectedSorraFeatured
                                ? 'font-bold text-[#111928]'
                                : 'font-normal text-[#4a4a4a]'
                            )}
                          >
                            {sf2.name}
                          </p>
                          <div
                            className={classnames(
                              'h-[3px] rounded-full',
                              idx === selectedSorraFeatured
                                ? 'bg-sorra-pink'
                                : 'bg-transparent'
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>
                </MaxWidthContainer>
                <div className="h-px w-full bg-gray-100"></div>

                <MaxWidthContainer
                  key={selectedSorraFeatured}
                  className="overflow-hidden px-4 pt-5 lg:px-0 lg:pt-8"
                >
                  <div className="mini-banner-carousel relative hidden lg:block">
                    {isHomeLoading ? (
                      <Carousel
                        swipeable={false}
                        emulateTouch={false}
                        stopOnHover={false}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        <div className="grid grid-cols-4 gap-6">
                          {[0, 1, 2, 3].map((i) => {
                            return (
                              <div key={i} className="aspect-[302/521]">
                                <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                                  <Skeleton width={'100%'} height={'100%'} />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Carousel>
                    ) : (
                      chunkSorraFeaturedProducts.length > 0 && (
                        <Carousel
                          swipeable={true}
                          emulateTouch={true}
                          stopOnHover={true}
                          showIndicators={false}
                          showStatus={false}
                          showThumbs={false}
                          renderArrowPrev={(
                            clickHandler,
                            hasPrev,
                            labelPrev
                          ) => {
                            return (
                              hasPrev && (
                                <button
                                  onClick={clickHandler}
                                  className="absolute -left-6 top-1/2 z-10 h-10 w-10 -translate-x-full -translate-y-1/2 rounded-full"
                                  style={{
                                    boxShadow:
                                      '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                  }}
                                >
                                  <img
                                    className="p-2.5"
                                    src="/svgs/index-horizontalScroll-arrowLeft.svg"
                                  ></img>
                                </button>
                              )
                            );
                          }}
                          renderArrowNext={(
                            clickHandler,
                            hasNext,
                            labelNext
                          ) => {
                            return (
                              hasNext && (
                                <button
                                  onClick={clickHandler}
                                  className="absolute -right-6 top-1/2 z-10 h-10 w-10 -translate-y-1/2 translate-x-full rounded-full"
                                  style={{
                                    boxShadow:
                                      '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                  }}
                                >
                                  <img
                                    className="p-2.5"
                                    src="/svgs/index-horizontalScroll-arrowRight.svg"
                                  ></img>
                                </button>
                              )
                            );
                          }}
                        >
                          {chunkSorraFeaturedProducts.map((chunk, idx) => {
                            return (
                              <div key={idx} className="grid grid-cols-4 gap-6">
                                {chunk.map((p) => {
                                  if (p.isBanner) {
                                    const image = p.image;
                                    return (
                                      <div
                                        key={`banner-${p.id}`}
                                        className="aspect-[302/521] w-full cursor-pointer overflow-hidden rounded-[10px] bg-white"
                                        onClick={() => {
                                          window.open(
                                            p.url,
                                            p.new_tab == true ? '_blank' : ''
                                          );
                                        }}
                                      >
                                        <StrapiImg
                                          src={image ? image.url : ''}
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                    );
                                  } else {
                                    const image = _.head(p.images);
                                    const stores = p.product_stores || [];
                                    const store = _.head(stores);
                                    const rating = _.get(p, 'rating', null);
                                    const origin = productOriginMap[p.origin];
                                    const facts = origin
                                      ? []
                                      : _.take(p.facts, 1);
                                    const restFacts = _.drop(
                                      p.facts,
                                      facts.length
                                    );

                                    const storeObj = store
                                      ? storeMap[store.store]
                                      : null;
                                    return (
                                      <div
                                        key={`product-${p.id}`}
                                        className="flex cursor-pointer flex-col bg-white pb-5 text-left"
                                        onClick={() => {
                                          window.location = `/product/${p.id}`;
                                        }}
                                      >
                                        <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                          <StrapiImg
                                            src={
                                              image
                                                ? image.url
                                                : `${window.location.origin}/images/placeholders/product.png`
                                            }
                                            className="h-full w-full object-cover"
                                          />
                                        </div>
                                        <div className="gap-y-1 pt-6">
                                          <div className="flex flex-col gap-y-0.5">
                                            {_.get(p, 'brand.name') && (
                                              <p className="truncate text-sm text-gray-800">
                                                {p.brand.name}
                                              </p>
                                            )}
                                            {_.get(p, 'en_name') && (
                                              <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                                {p.name}
                                              </p>
                                            )}
                                            {_.get(p, 'name') && (
                                              <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                                {p.en_name}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        {rating && (
                                          <p className="truncate pt-1 text-lg font-medium text-sorra-pink">
                                            {`${
                                              rating.rate < 49
                                                ? '🤔'
                                                : rating.rate < 79
                                                  ? '👌'
                                                  : '❤️'
                                            } ${rating.rate}%`}
                                            <span className="font-regular text-base text-sorra-gray-500">
                                              {' (' + rating.total + ')'}
                                            </span>
                                          </p>
                                        )}
                                        <div className="pt-2" />
                                        <div className="flex flex-row flex-wrap items-center gap-2">
                                          {origin && (
                                            <div
                                              style={{
                                                background:
                                                  'rgba(252, 225, 229, 0.50)',
                                              }}
                                              className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                            >
                                              {origin.name}製
                                            </div>
                                          )}
                                          {facts.map((fact) => {
                                            return (
                                              <div
                                                key={fact.id}
                                                style={{
                                                  background:
                                                    'rgba(225, 244, 244, 0.50)',
                                                }}
                                                className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                              >
                                                {fact.name}
                                              </div>
                                            );
                                          })}
                                          {restFacts.length > 0 && (
                                            <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                              +{restFacts.length}
                                            </div>
                                          )}
                                          {/* <div className="grow" /> */}
                                        </div>
                                        <div className="mb-1 w-full pt-3 text-lg font-medium text-[#111928]">
                                          {storeObj && store.price && (
                                            <div className="flex items-center">
                                              <div className="mr-1 shrink-0">
                                                HK$ {store.price} @
                                              </div>
                                              {storeObj.image &&
                                              storeObj.image.url != '' ? (
                                                <div className="flex justify-start">
                                                  <img
                                                    className="h-[16px] object-contain pl-1"
                                                    src={storeObj.image.url}
                                                  ></img>
                                                </div>
                                              ) : (
                                                <div className="min-w-0 grow truncate font-medium text-sorra-gray-900">
                                                  {storeObj.name}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {stores.length - 1 > 0 ? (
                                            <div className="mt-1 text-sm font-normal text-sorra-gray-800">
                                              {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                            </div>
                                          ) : (
                                            <div className="mt-1 min-h-5" />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            );
                          })}
                        </Carousel>
                      )
                    )}
                  </div>
                  <div className="mobile-mini-banner-carousel relative block w-full lg:hidden">
                    {isHomeLoading ? (
                      <Carousel
                        swipeable={false}
                        emulateTouch={false}
                        stopOnHover={false}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        <div className="grid grid-cols-2 gap-2.5 pr-2.5">
                          {[0, 1].map((i) => {
                            return (
                              <div key={i} className="aspect-[140/342]">
                                <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                                  <Skeleton width={'100%'} height={'100%'} />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Carousel>
                    ) : (
                      chunkMobileSorraFeaturedProducts.length > 0 && (
                        <Carousel
                          swipeable={true}
                          emulateTouch={true}
                          stopOnHover={true}
                          showIndicators={false}
                          showStatus={false}
                          showThumbs={false}
                          showArrows={false}
                        >
                          {chunkMobileSorraFeaturedProducts.map(
                            (chunk, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="grid grid-cols-2 gap-2.5 pr-2.5"
                                >
                                  {chunk.map((p) => {
                                    if (p.isBanner) {
                                      const image = p.mobile_image;
                                      return (
                                        <div
                                          key={`banner-${p.id}`}
                                          className="aspect-[140/342] h-full w-full cursor-pointer overflow-hidden rounded-[10px]"
                                          onClick={() => {
                                            window.open(
                                              p.url,
                                              p.new_tab == true ? '_blank' : ''
                                            );
                                          }}
                                        >
                                          <StrapiImg
                                            src={image ? image.url : ''}
                                            className="h-full w-full object-cover"
                                          />
                                        </div>
                                      );
                                    } else {
                                      const image = _.head(p.images);
                                      const stores = p.product_stores || [];
                                      const store = _.head(stores);
                                      const rating = _.get(p, 'rating', null);
                                      const origin = productOriginMap[p.origin];
                                      const facts = origin
                                        ? []
                                        : _.take(p.facts, 1);
                                      const restFacts = _.drop(
                                        p.facts,
                                        facts.length
                                      );

                                      const storeObj = store
                                        ? storeMap[store.store]
                                        : null;
                                      return (
                                        <div
                                          key={`product-${p.id}`}
                                          className="flex cursor-pointer flex-col text-left"
                                          onClick={() => {
                                            window.location = `/product/${p.id}`;
                                          }}
                                        >
                                          <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                            <StrapiImg
                                              src={
                                                image
                                                  ? image.url
                                                  : `${window.location.origin}/images/placeholders/product.png`
                                              }
                                              className="h-full w-full object-cover"
                                            />
                                          </div>
                                          <div className="gap-y-1 pt-6">
                                            <div className="flex flex-col gap-y-0.5">
                                              {_.get(p, 'brand.name') && (
                                                <p className="truncate text-sm text-gray-800">
                                                  {p.brand.name}
                                                </p>
                                              )}
                                              {_.get(p, 'en_name') && (
                                                <p className="truncate text-sm font-medium text-[#111928]">
                                                  {p.name}
                                                </p>
                                              )}
                                              {_.get(p, 'name') && (
                                                <p className="truncate text-sm font-medium text-[#111928]">
                                                  {p.en_name}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          {/* <div className="grow" /> */}
                                          {rating && (
                                            <div className="mt-1 flex items-center">
                                              <p className="text-sm font-bold text-sorra-pink">
                                                {`${
                                                  rating.rate < 49
                                                    ? '🤔'
                                                    : rating.rate < 79
                                                      ? '👌'
                                                      : '❤️'
                                                } ${rating.rate}% `}
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                ({`${rating.total}`})
                                              </p>
                                            </div>
                                          )}
                                          <div className="mt-1.5 flex flex-row flex-wrap items-center gap-2">
                                            {origin && (
                                              <div
                                                style={{
                                                  background:
                                                    'rgba(252, 225, 229, 0.50)',
                                                }}
                                                className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                              >
                                                {origin.name}製
                                              </div>
                                            )}
                                            {facts.map((fact) => {
                                              return (
                                                <div
                                                  key={fact.id}
                                                  style={{
                                                    background:
                                                      'rgba(225, 244, 244, 0.50)',
                                                  }}
                                                  className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                                >
                                                  {fact.name}
                                                </div>
                                              );
                                            })}
                                            {restFacts.length > 0 && (
                                              <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                                +{restFacts.length}
                                              </div>
                                            )}
                                          </div>
                                          <div className="mt-1.5 w-full text-sm font-medium text-[#111928]">
                                            {storeObj && store.price && (
                                              <div className="flex items-center">
                                                <div className="mr-1 shrink-0">
                                                  HK$ {store.price} @
                                                </div>
                                                {storeObj.image &&
                                                storeObj.image.url != '' ? (
                                                  <div className="flex justify-start">
                                                    <img
                                                      className="h-[16px] object-contain pl-1"
                                                      src={storeObj.image.url}
                                                    ></img>
                                                  </div>
                                                ) : (
                                                  <div className="min-w-0 grow truncate text-sm text-sorra-gray-900">
                                                    {storeObj.name}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            {stores.length - 1 > 0 && (
                                              <div className="mt-1 text-xs font-normal text-sorra-gray-800">
                                                {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              );
                            }
                          )}
                        </Carousel>
                      )
                    )}
                  </div>
                </MaxWidthContainer>
              </div>
            )}
          </div>

          {/* new review */}
          <div
            className={
              'pt-6 lg:pt-20' +
              (new_reviews.length == 0 && isHomeLoading == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div
                className={
                  'text-xl font-bold text-sorra-gray-900 lg:text-2xl' +
                  (isHomeLoading == true ? ' hidden' : '')
                }
              >
                近期用後感
              </div>
            </MaxWidthContainer>
            {/* new reviews desktop */}
            <MaxWidthContainer className="relative mt-[18px] hidden h-[424px] px-4 lg:block">
              {isHomeLoading ? (
                <IndexCommentCarouselMobile
                  className="home-comments h-full w-full overflow-visible"
                  props={{ showArrows: true, showIndicators: true }}
                >
                  <div className="grid h-full w-full grid-cols-3 gap-x-[26px]">
                    {[0, 1, 2].map((idx) => {
                      return (
                        <div
                          key={idx}
                          className="index-comment-item relative h-full cursor-pointer"
                        >
                          <Skeleton width={'100%'} height={'100%'} />
                        </div>
                      );
                    })}
                  </div>
                </IndexCommentCarouselMobile>
              ) : (
                <IndexCommentCarouselMobile
                  className="home-comments h-full w-full overflow-visible"
                  props={{ showArrows: true, showIndicators: true }}
                >
                  {[0, 1, 2, 3, 4, 5].map((index) => {
                    return (
                      <div
                        key={index}
                        className="grid h-full w-full grid-cols-3 gap-x-[26px]"
                      >
                        {[0, 1, 2].map((i) => {
                          var factor = '';
                          var productRedirectID = '';
                          var productImageURL = '';
                          var productBrandName = '';
                          var productName = '';
                          var productNameEN = '';
                          var sorraUserPicture = '';
                          var maskName = '';
                          var ageString = '';
                          var commentCountString = '';
                          var rating = '';
                          var certImages = [];
                          var reviewTags = [];
                          var commentString = '';
                          var comparisonImages = [];
                          var timeString = '';
                          var source = '';
                          var isRealCert = false;
                          var isActivity = false;

                          if (index * 3 + i < new_reviews.length) {
                            var reviewObject = new_reviews[index * 3 + i];

                            if (reviewObject.product.images != null) {
                              if (reviewObject.product.images.length > 0) {
                                productImageURL =
                                  reviewObject.product.images[0].url;
                              }
                            }

                            if (reviewObject.product.brand != null) {
                              productBrandName =
                                reviewObject.product.brand.name;
                            }

                            productRedirectID = reviewObject.product.id;
                            productName = reviewObject.product.name;
                            productNameEN = reviewObject.product.en_name;

                            if (reviewObject.sorra_user != null) {
                              if (reviewObject.sorra_user.picture != null) {
                                sorraUserPicture =
                                  reviewObject.sorra_user.picture;
                              }

                              if (reviewObject.sorra_user?.age_range) {
                                ageString += `| ${reviewObject.sorra_user.age_range} 歲`;
                              }

                              if (reviewObject.sorra_user?.review_count) {
                                commentCountString += `| ${reviewObject.sorra_user.review_count}則評價`;
                              }
                            }

                            maskName = reviewObject.mask_name;

                            const reviewFactorId = reviewObject.factors[0];
                            factor = factorMap[reviewFactorId]
                              ? factorMap[reviewFactorId].name
                              : '';

                            switch (reviewObject.score) {
                              case 0:
                                rating = '👌 中性';
                                break;

                              case 1:
                                rating = '❤️ 好評';
                                break;

                              case -1:
                                rating = '👿 差評';
                                break;

                              default:
                                break;
                            }

                            certImages = reviewObject.cert_images;
                            reviewTags = reviewObject.review_tags;
                            commentString = reviewObject.comment;
                            comparisonImages = reviewObject.comparison_images;
                            isRealCert = reviewObject.is_real_cert;
                            isActivity = reviewObject.activity ? true : false;

                            var time = new Date(reviewObject.created_at);
                            timeString =
                              time.getDate() +
                              '/' +
                              (time.getMonth() + 1) +
                              '/' +
                              time.getFullYear() +
                              ' ' +
                              (time.getHours() < 10
                                ? '0' + time.getHours()
                                : time.getHours()) +
                              ':' +
                              (time.getMinutes() < 10
                                ? '0' + time.getMinutes()
                                : time.getMinutes());

                            source = reviewSourceMap[reviewObject.source]
                              ? reviewSourceMap[reviewObject.source].name
                              : '';
                            if (source == 'user') {
                              source = 'Sorra官網';
                            } else if (source == 'ig') {
                              source = 'Sorra IG社群';
                            }
                          }

                          return (
                            <a
                              key={productRedirectID}
                              href={'/product/' + productRedirectID}
                              className="index-comment-item relative h-full cursor-pointer px-[20px] py-[16px]"
                            >
                              <div className="flex h-[88px] gap-x-[12px] rounded-[8px] bg-white px-[16px] py-[10px]">
                                <img
                                  src={
                                    productImageURL == ''
                                      ? '/images/placeholders/product.png'
                                      : productImageURL
                                  }
                                  className="index-comment-item-product-image rounded-[4px]"
                                ></img>
                                <div className="flex grow items-center">
                                  <div className="grid w-full">
                                    <div className="text-left text-xs text-sorra-gray-800">
                                      {productBrandName}
                                    </div>
                                    <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                      {productName}
                                    </div>
                                    <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                      {productNameEN}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-[22px] flex items-center gap-x-2 px-[4px]">
                                <div className="h-[40px] w-[40px]">
                                  {sorraUserPicture ? (
                                    <img
                                      className="h-full w-full rounded-full"
                                      src={sorraUserPicture}
                                    />
                                  ) : (
                                    <div className="flex h-full w-full items-center justify-center rounded-full bg-[#D6DDFB80] text-sorra-blue">
                                      {`${maskName.charAt(0)}${maskName.charAt(
                                        maskName.length - 1
                                      )}`}
                                    </div>
                                  )}
                                </div>
                                <div className="grow">
                                  <div className="text-left text-sm font-bold text-[#111928]">
                                    {maskName}
                                  </div>
                                  <div className="mt-1 text-left text-xs text-sorra-gray-800">
                                    {!factor ? '無註明' : factor} {ageString}{' '}
                                    {commentCountString}
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center gap-x-2 pt-4">
                                <span className="text-base font-bold text-[#111928]">
                                  {rating}
                                </span>

                                {isRealCert ? (
                                  <div className="cursor-default rounded-md bg-[#D6DDFB80] px-2 py-1 text-[12px] text-sorra-blue">
                                    真實用家認證
                                  </div>
                                ) : null}

                                {isActivity ? (
                                  <div className="cursor-default rounded-md bg-[#FFDCC680] px-2 py-1 text-[12px] text-[#FFA66D]">
                                    試用品
                                  </div>
                                ) : null}

                                {reviewTags.map((item) => {
                                  // const targetTag = arrReviewTagsState.find(
                                  //   (tagItem) => item == tagItem.id
                                  // );
                                  const { name, text_color, background_color } =
                                    reviewTagMap[item];
                                  return (
                                    <div
                                      style={{
                                        color: text_color, //targetTag.text_color,
                                        background: background_color, //targetTag.background_color,
                                      }}
                                      className={`cursor-default rounded-md px-2 py-1 text-[12px]`}
                                    >
                                      {name}
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="mb-[16px] line-clamp-2 h-[52px] w-full px-[4px] pt-1 text-left text-base text-[#111928]">
                                {commentString}
                              </div>

                              {comparisonImages.length > 0 ||
                              certImages.length > 0 ? (
                                <div className="flex items-center space-x-2 px-[4px] pt-[16px]">
                                  {certImages.map((item, index) => (
                                    <img
                                      key={item.id}
                                      src={item.url}
                                      className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                    />
                                  ))}
                                  {comparisonImages.map((item, index) => (
                                    <img
                                      key={item.id}
                                      src={item.url}
                                      className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                    />
                                  ))}
                                </div>
                              ) : null}

                              <div className="mb-0 place-self-end px-[4px] pt-[24px] text-left text-xs text-[#4A4A4A]">
                                {timeString} 在 {source} 評價
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    );
                  })}
                </IndexCommentCarouselMobile>
              )}
            </MaxWidthContainer>

            {/* new reviews mobile */}
            <div className="index-featured-outer">
              <MaxWidthContainer className="index-featured-inner mt-[18px] flex h-[424px] gap-x-[10px] px-4 lg:hidden">
                {isHomeLoading ? (
                  <>
                    {[0, 1, 2].map((index) => {
                      return (
                        <div
                          key={index}
                          className="index-comment-item relative h-full w-[300px] cursor-pointer"
                        >
                          <Skeleton width={'100%'} height={'100%'} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {new_reviews.map((element, elementIndex) => {
                      var factor = '';
                      var productRedirectID = '';
                      var productImageURL = '';
                      var productBrandName = '';
                      var productName = '';
                      var productNameEN = '';
                      var sorraUserPicture = '';
                      var maskName = '';
                      var ageString = '';
                      var commentCountString = '';
                      var rating = '';
                      var certImages = [];
                      var reviewTags = [];
                      var commentString = '';
                      var comparisonImages = [];
                      var timeString = '';
                      var source = '';
                      var isRealCert = false;
                      var isActivity = false;

                      var reviewObject = element;

                      // console.log(reviewObject)

                      if (reviewObject.product.images != null) {
                        if (reviewObject.product.images.length > 0) {
                          productImageURL = reviewObject.product.images[0].url;
                        }
                      }

                      if (reviewObject.product.brand != null) {
                        productBrandName = reviewObject.product.brand.name;
                      }

                      productRedirectID = reviewObject.product.id;
                      productName = reviewObject.product.name;
                      productNameEN = reviewObject.product.en_name;

                      if (reviewObject.sorra_user != null) {
                        if (reviewObject.sorra_user.picture != null) {
                          sorraUserPicture = reviewObject.sorra_user.picture;
                        }

                        if (reviewObject.sorra_user?.age_range) {
                          ageString += `| ${reviewObject.sorra_user.age_range} 歲`;
                        }

                        if (reviewObject.sorra_user?.review_count) {
                          commentCountString += `| ${reviewObject.sorra_user.review_count}則評價`;
                        }
                      }

                      maskName = reviewObject.mask_name;

                      const reviewFactorId = reviewObject.factors[0];
                      factor = factorMap[reviewFactorId]
                        ? factorMap[reviewFactorId].name
                        : '';

                      switch (reviewObject.score) {
                        case 0:
                          rating = '👌 中性';
                          break;

                        case 1:
                          rating = '❤️ 好評';
                          break;

                        case -1:
                          rating = '👿 差評';
                          break;

                        default:
                          break;
                      }

                      certImages = reviewObject.cert_images;
                      reviewTags = reviewObject.review_tags;
                      commentString = reviewObject.comment;
                      comparisonImages = reviewObject.comparison_images;
                      isRealCert = reviewObject.is_real_cert;
                      isActivity = reviewObject.activity ? true : false;

                      var time = new Date(reviewObject.created_at);
                      timeString =
                        time.getDate() +
                        '/' +
                        (time.getMonth() + 1) +
                        '/' +
                        time.getFullYear() +
                        ' ' +
                        (time.getHours() < 10
                          ? '0' + time.getHours()
                          : time.getHours()) +
                        ':' +
                        (time.getMinutes() < 10
                          ? '0' + time.getMinutes()
                          : time.getMinutes());

                      source = reviewSourceMap[reviewObject.source]
                        ? reviewSourceMap[reviewObject.source].name
                        : '';
                      if (source == 'user') {
                        source = 'Sorra官網';
                      } else if (source == 'ig') {
                        source = 'Sorra IG社群';
                      }

                      return (
                        <div
                          key={productRedirectID}
                          className="index-comment-item relative h-full w-[300px] cursor-pointer px-[20px] py-[16px]"
                          onClick={() => {
                            window.location.href =
                              '/product/' + productRedirectID;
                          }}
                        >
                          <div className="flex h-[88px] gap-x-[12px] rounded-[8px] bg-white px-[16px] py-[10px]">
                            <img
                              src={
                                productImageURL == ''
                                  ? '/images/placeholders/product.png'
                                  : productImageURL
                              }
                              className="index-comment-item-product-image rounded-[4px]"
                            ></img>
                            <div className="flex grow items-center">
                              <div className="grid w-full">
                                <div className="text-left text-xs text-sorra-gray-800">
                                  {productBrandName}
                                </div>
                                <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                  {productName}
                                </div>
                                <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                                  {productNameEN}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-[22px] flex items-center gap-x-2 px-[4px]">
                            <div className="h-[40px] w-[40px]">
                              {sorraUserPicture ? (
                                <img
                                  className="h-full w-full rounded-full"
                                  src={sorraUserPicture}
                                />
                              ) : (
                                <div className="flex h-full w-full items-center justify-center rounded-full bg-[#D6DDFB80] text-sorra-blue">
                                  {`${maskName.charAt(0)}${maskName.charAt(
                                    maskName.length - 1
                                  )}`}
                                </div>
                              )}
                            </div>
                            <div className="grow">
                              <div className="text-left text-sm font-bold text-[#111928]">
                                {maskName}
                              </div>
                              <div className="mt-1 text-left text-xs text-sorra-gray-800">
                                {!factor ? '無註明' : factor} {ageString}{' '}
                                {commentCountString}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-x-2 pt-4">
                            <span className="text-base font-bold text-[#111928]">
                              {rating}
                            </span>

                            {isRealCert ? (
                              <div className="cursor-default rounded-md bg-[#D6DDFB80] px-2 py-1 text-[12px] text-sorra-blue">
                                真實用家認證
                              </div>
                            ) : null}

                            {isActivity ? (
                              <div className="cursor-default rounded-md bg-[#FFDCC680] px-2 py-1 text-[12px] text-[#FFA66D]">
                                試用品
                              </div>
                            ) : null}

                            {reviewTags.map((item) => {
                              // const targetTag = arrReviewTagsState.find(
                              //   (tagItem) => item == tagItem.id
                              // );
                              const { name, text_color, background_color } =
                                reviewTagMap[item];
                              return (
                                <div
                                  style={{
                                    color: text_color, //targetTag.text_color,
                                    background: background_color, //targetTag.background_color,
                                  }}
                                  className={`cursor-default rounded-md px-2 py-1 text-[12px]`}
                                >
                                  {name}
                                </div>
                              );
                            })}
                          </div>

                          <div className="mb-[16px] line-clamp-2 h-[52px] w-full px-[4px] pt-1 text-left text-base text-[#111928]">
                            {commentString}
                          </div>

                          {comparisonImages.length > 0 ||
                          certImages.length > 0 ? (
                            <div className="flex items-center space-x-2 px-[4px] pt-[16px]">
                              {certImages.map((item, index) => (
                                <img
                                  src={item.url}
                                  className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                />
                              ))}
                              {comparisonImages.map((item, index) => (
                                <img
                                  src={item.url}
                                  className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                                />
                              ))}
                            </div>
                          ) : null}

                          <div className="mb-0 place-self-end px-[4px] pt-[24px] text-left text-xs text-[#4A4A4A]">
                            {timeString} 在 {source} 評價
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </MaxWidthContainer>
            </div>
          </div>
          <div className="relative mt-10 pb-12 lg:mt-24 lg:pb-16">
            <div className="absolute flex h-full w-full flex-col">
              <MaxWidthContainer className="aspect-[1290/128] shrink-0" />
              <div
                className="grow"
                style={{
                  background:
                    'radial-gradient(132.98% 110.07% at 93.4% 20.5%, rgba(251, 210, 216, 0.30) 3.61%, rgba(255, 237, 239, 0.30) 69.68%)',
                }}
              ></div>
            </div>
            <div className="relative">
              <MaxWidthContainer className="relative">
                <div className="hidden lg:block">
                  <div
                    className="relative aspect-[1290/256] cursor-pointer overflow-hidden rounded-lg"
                    onClick={() => {
                      window.location.href = '/membership';
                    }}
                  >
                    <img
                      className="h-full w-full object-cover"
                      src="/images/desktop_join_member.png"
                    />
                  </div>
                </div>
                <div className="block lg:hidden">
                  <div
                    className="relative aspect-[375/428] cursor-pointer"
                    onClick={() => {
                      window.location.href = '/membership';
                    }}
                  >
                    <img
                      className="h-full w-full object-cover"
                      src="/images/mobile_join_member.png"
                    />
                  </div>
                </div>
              </MaxWidthContainer>

              {newActivities.length > 0 && (
                <MaxWidthContainer className="px-4 pt-6 lg:px-0 lg:pt-11">
                  <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                    會員免費試用
                  </div>
                  <div className="grid grid-cols-1 gap-3 pt-[22px] lg:grid-cols-3 lg:gap-6 lg:pt-7">
                    {newActivities.map((act) => {
                      const { id, name } = act;
                      const thumbnail = _.get(act, 'thumbnail.url');
                      return (
                        <div
                          key={id}
                          // href={`/campaign/${act.name}`}
                          className="flex cursor-pointer flex-col gap-y-4"
                          onClick={() => {
                            window.location.href = `/campaign/${name}`;
                          }}
                        >
                          <div className="relative aspect-[343/193] overflow-hidden rounded-lg lg:aspect-[405/228]">
                            <StrapiImg
                              src={thumbnail}
                              className="h-full w-full object-cover"
                            />
                            {id === 10 && (
                              <img
                                className="h-px w-px"
                                src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32685117.406872372;dc_trk_aid=598558842;dc_trk_cid=224181113;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                              />
                            )}
                            {id === 12 && (
                              <img
                                className="h-px w-px"
                                src="https://ad.doubleclick.net/ddm/trackimp/N1003017.4334518SORRAHKFACEBOOK/B32821884.407904241;dc_trk_aid=599933411;dc_trk_cid=225687372;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=$%7BGDPR%7D;gdpr_consent=$%7BGDPR_CONSENT_755%7D;ltd=;dc_tdv=1"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-center pt-6 lg:pt-7">
                    <a
                      className="block w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                      href="/campaign"
                    >
                      查看全部
                    </a>
                  </div>
                </MaxWidthContainer>
              )}

              {rewards.length > 0 && (
                <MaxWidthContainer className="px-4 pt-6 lg:px-0 lg:pt-11">
                  <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                    免費換領
                  </div>
                  <div className="grid grid-cols-2 gap-4 pt-[22px] lg:grid-cols-4 lg:gap-6 lg:pt-7">
                    {rewards.map((reward) => {
                      const { inventory, score, original_score } = reward;
                      return (
                        <div
                          key={reward.id}
                          className={classnames(
                            'flex flex-col',
                            inventory > 0 ? 'cursor-pointer' : ''
                          )}
                          onClick={() => {
                            if (inventory > 0) {
                              window.location.href = `/rewards/gift/${reward.id}`;
                            }
                          }}
                        >
                          <div className="aspect-square overflow-hidden rounded-2xl">
                            <StrapiImg
                              src={
                                _.get(reward, 'image.url') ||
                                `${window.location.origin}/images/placeholders/product.png`
                              }
                              className="h-full w-full object-cover"
                            />
                          </div>
                          <div className="mt-6 flex flex-col gap-y-1">
                            {_.get(reward, 'brand.name') && (
                              <p className="text-sm text-gray-800">
                                {reward.brand.name}
                              </p>
                            )}
                            {_.get(reward, 'en_name') && (
                              <p className="text-base font-medium text-[#111928] lg:text-lg">
                                {reward.name}
                              </p>
                            )}
                            {_.get(reward, 'name') && (
                              <p className="text-base font-medium text-[#111928] lg:text-lg">
                                {reward.en_name}
                              </p>
                            )}
                          </div>
                          <div className="grow" />
                          <div className="mt-4 min-h-12">
                            <div className="flex">
                              <img
                                src="/svgs/sorra-points-small.svg"
                                className="mr-2"
                              ></img>
                              <div className="flex gap-x-2 text-base font-medium text-sorra-gray-900">
                                <p
                                  className={
                                    reward.original_score > 0
                                      ? 'text-sorra-pink'
                                      : ''
                                  }
                                >
                                  {'$' + reward.score}
                                </p>
                                {reward.original_score > 0 && (
                                  <p className="font-normal line-through">{`$${reward.original_score}`}</p>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                'mt-0.5 text-xs font-medium text-sorra-gray-800' +
                                (inventory == 0 || inventory >= 2
                                  ? ' hidden'
                                  : '')
                              }
                            >
                              剩餘最後{' '}
                              <span className="text-sorra-pink">
                                {inventory}
                              </span>{' '}
                              件產品
                            </div>
                            <div
                              className={
                                'mt-0.5 text-xs font-medium text-sorra-gray-800' +
                                (inventory > 0 ? ' hidden' : '')
                              }
                            >
                              換完了～ 請期待新一輪獎賞！
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-center pt-6 lg:pt-7">
                    <a
                      className="block w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                      href="/rewards"
                    >
                      查看全部
                    </a>
                  </div>
                </MaxWidthContainer>
              )}
            </div>
          </div>

          {sorraFeatured2.length > 0 && (
            <div>
              <MaxWidthContainer className="px-4 pt-12 lg:px-0 lg:pt-14">
                <div className="text-xl font-bold text-[#111928] lg:text-2xl">
                  更多好物雷品發現！
                </div>
                <div className="no-scrollbar mt-6 flex w-full flex-row gap-x-8 overflow-x-auto">
                  {sorraFeatured2.map((sf2, idx) => {
                    return (
                      <div
                        key={sf2.id}
                        className="flex shrink-0 cursor-pointer flex-col gap-y-4"
                        onClick={() => {
                          setSelectedSorraFeatured2(idx);
                        }}
                      >
                        <p
                          className={classnames(
                            'text-base lg:text-xl',
                            idx === selectedSorraFeatured2
                              ? 'font-bold'
                              : 'font-normal'
                          )}
                        >
                          {sf2.name}
                        </p>
                        <div
                          className={classnames(
                            'h-[3px] rounded-full',
                            idx === selectedSorraFeatured2
                              ? 'bg-sorra-pink'
                              : 'bg-transparent'
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </MaxWidthContainer>
              <div className="h-px w-full bg-gray-100"></div>

              <MaxWidthContainer
                key={selectedSorraFeatured2}
                className="overflow-hidden px-4 pt-5 lg:px-0 lg:pt-8"
              >
                {isLg ? (
                  <div className="mini-banner-carousel relative hidden lg:block">
                    {chunkSorraFeatured2Products.length > 0 && (
                      <Carousel
                        swipeable={true}
                        emulateTouch={true}
                        stopOnHover={true}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        renderArrowPrev={(clickHandler, hasPrev, labelPrev) => {
                          return (
                            hasPrev && (
                              <button
                                onClick={clickHandler}
                                className="absolute -left-6 top-1/2 z-10 h-10 w-10 -translate-x-full -translate-y-1/2 rounded-full"
                                style={{
                                  boxShadow:
                                    '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                }}
                              >
                                <img
                                  className="p-2.5"
                                  src="/svgs/index-horizontalScroll-arrowLeft.svg"
                                ></img>
                              </button>
                            )
                          );
                        }}
                        renderArrowNext={(clickHandler, hasNext, labelNext) => {
                          return (
                            hasNext && (
                              <button
                                onClick={clickHandler}
                                className="absolute -right-6 top-1/2 z-10 h-10 w-10 -translate-y-1/2 translate-x-full rounded-full"
                                style={{
                                  boxShadow:
                                    '0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1), 0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05)',
                                }}
                              >
                                <img
                                  className="p-2.5"
                                  src="/svgs/index-horizontalScroll-arrowRight.svg"
                                ></img>
                              </button>
                            )
                          );
                        }}
                      >
                        {chunkSorraFeatured2Products.map((chunk, idx) => {
                          return (
                            <div key={idx} className="grid grid-cols-4 gap-6">
                              {chunk.map((p) => {
                                if (p.isBanner) {
                                  const image = p.image;
                                  return (
                                    <div
                                      key={`banner-${p.id}`}
                                      className="aspect-[301/541] h-full w-full cursor-pointer overflow-hidden rounded-[10px] bg-white"
                                      onClick={() => {
                                        window.open(
                                          p.url,
                                          p.new_tab == true ? '_blank' : ''
                                        );
                                      }}
                                    >
                                      <StrapiImg
                                        src={image ? image.url : ''}
                                        className="h-full w-full object-cover"
                                      />
                                    </div>
                                  );
                                } else {
                                  const image = _.head(p.images);
                                  const stores = p.product_stores || [];
                                  const store = _.head(stores);
                                  const rating = _.get(p, 'rating', null);
                                  const origin = productOriginMap[p.origin];
                                  const facts = origin
                                    ? []
                                    : _.take(p.facts, 1);
                                  const restFacts = _.drop(
                                    p.facts,
                                    facts.length
                                  );

                                  const storeObj = store
                                    ? storeMap[store.store]
                                    : null;
                                  return (
                                    <div
                                      key={`product-${p.id}`}
                                      className="flex cursor-pointer flex-col bg-white pb-5 text-left"
                                      onClick={() => {
                                        window.location = `/product/${p.id}`;
                                      }}
                                    >
                                      <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                        <StrapiImg
                                          src={
                                            image
                                              ? image.url
                                              : `${window.location.origin}/images/placeholders/product.png`
                                          }
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                      <div className="gap-y-1 py-6">
                                        <div className="mt-6 flex flex-col gap-y-0.5">
                                          {_.get(p, 'brand.name') && (
                                            <p className="truncate text-sm text-gray-800">
                                              {p.brand.name}
                                            </p>
                                          )}
                                          {_.get(p, 'en_name') && (
                                            <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                              {p.name}
                                            </p>
                                          )}
                                          {_.get(p, 'name') && (
                                            <p className="truncate text-base font-medium text-[#111928] lg:text-lg">
                                              {p.en_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="grow" />
                                      <div className="mb-1 w-full text-lg text-[#111928]">
                                        {storeObj && store.price && (
                                          <div className="flex items-center">
                                            <div className="mr-1 shrink-0">
                                              HK$ {store.price} @
                                            </div>
                                            {storeObj.image &&
                                            storeObj.image.url != '' ? (
                                              <div className="flex justify-start">
                                                <img
                                                  className="h-[16px] object-contain pl-1"
                                                  src={storeObj.image.url}
                                                ></img>
                                              </div>
                                            ) : (
                                              <div className="min-w-0 grow truncate text-sm font-medium text-sorra-gray-900">
                                                {storeObj.name}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {stores.length - 1 > 0 ? (
                                          <div className="mt-1 text-sm font-normal text-sorra-gray-800">
                                            {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                          </div>
                                        ) : (
                                          <div className="mt-1 min-h-5" />
                                        )}
                                      </div>
                                      <div className="flex flex-row flex-wrap items-center gap-2">
                                        {origin && (
                                          <div
                                            style={{
                                              background:
                                                'rgba(252, 225, 229, 0.50)',
                                            }}
                                            className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                          >
                                            {origin.name}製
                                          </div>
                                        )}
                                        {facts.map((fact) => {
                                          return (
                                            <div
                                              key={fact.id}
                                              style={{
                                                background:
                                                  'rgba(225, 244, 244, 0.50)',
                                              }}
                                              className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                            >
                                              {fact.name}
                                            </div>
                                          );
                                        })}
                                        {restFacts.length > 0 && (
                                          <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                            +{restFacts.length}
                                          </div>
                                        )}
                                        <div className="grow" />
                                        {rating && (
                                          <p className="text-sm font-bold text-gray-800">{`${
                                            rating.rate < 49
                                              ? '🤔'
                                              : rating.rate < 79
                                                ? '👌'
                                                : '❤️'
                                          }${rating.rate}%`}</p>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                ) : (
                  <div className="mobile-mini-banner-carousel relative w-full">
                    {chunkMobileSorraFeatured2Products.length > 0 && (
                      <Carousel
                        swipeable={true}
                        emulateTouch={true}
                        stopOnHover={true}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        showArrows={false}
                      >
                        {chunkMobileSorraFeatured2Products.map((chunk, idx) => {
                          return (
                            <div
                              key={idx}
                              className="grid grid-cols-2 gap-2.5 pr-2.5"
                            >
                              {chunk.map((p) => {
                                if (p.isBanner) {
                                  const image = p.mobile_image;
                                  return (
                                    <div
                                      key={`banner-${p.id}`}
                                      className="aspect-[140/342] h-full w-full cursor-pointer overflow-hidden rounded-[10px]"
                                      onClick={() => {
                                        window.open(
                                          p.url,
                                          p.new_tab == true ? '_blank' : ''
                                        );
                                      }}
                                    >
                                      <StrapiImg
                                        src={image ? image.url : ''}
                                        className="h-full w-full object-cover"
                                      />
                                    </div>
                                  );
                                } else {
                                  const image = _.head(p.images);
                                  const stores = p.product_stores || [];
                                  const store = _.head(stores);
                                  const rating = _.get(p, 'rating', null);
                                  const origin = productOriginMap[p.origin];
                                  const facts = origin
                                    ? []
                                    : _.take(p.facts, 1);
                                  const restFacts = _.drop(
                                    p.facts,
                                    facts.length
                                  );

                                  const storeObj = store
                                    ? storeMap[store.store]
                                    : null;
                                  return (
                                    <div
                                      key={`product-${p.id}`}
                                      className="flex cursor-pointer flex-col text-left"
                                      onClick={() => {
                                        window.location = `/product/${p.id}`;
                                      }}
                                    >
                                      <div className="aspect-square overflow-hidden rounded-2xl bg-gray-50 bg-opacity-50">
                                        <StrapiImg
                                          src={
                                            image
                                              ? image.url
                                              : `${window.location.origin}/images/placeholders/product.png`
                                          }
                                          className="h-full w-full object-cover"
                                        />
                                      </div>
                                      <div className="gap-y-1 py-6">
                                        <div className="mt-6 flex flex-col gap-y-0.5">
                                          {_.get(p, 'brand.name') && (
                                            <p className="truncate text-sm text-gray-800">
                                              {p.brand.name}
                                            </p>
                                          )}
                                          {_.get(p, 'en_name') && (
                                            <p className="truncate text-sm font-medium text-[#111928]">
                                              {p.name}
                                            </p>
                                          )}
                                          {_.get(p, 'name') && (
                                            <p className="truncate text-sm font-medium text-[#111928]">
                                              {p.en_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {/* <div className="grow" /> */}
                                      {rating && (
                                        <div className="flex items-center">
                                          <p className="text-sm font-bold text-sorra-pink">
                                            {`${
                                              rating.rate < 49
                                                ? '🤔'
                                                : rating.rate < 79
                                                  ? '👌'
                                                  : '❤️'
                                            } ${rating.rate}% `}
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            ({`${rating.total}`})
                                          </p>
                                        </div>
                                      )}
                                      <div className="mt-2.5 flex flex-row flex-wrap items-center gap-2">
                                        {origin && (
                                          <div
                                            style={{
                                              background:
                                                'rgba(252, 225, 229, 0.50)',
                                            }}
                                            className="rounded-md px-2 py-1 text-xs font-medium text-sorra-pink"
                                          >
                                            {origin.name}製
                                          </div>
                                        )}
                                        {facts.map((fact) => {
                                          return (
                                            <div
                                              key={fact.id}
                                              style={{
                                                background:
                                                  'rgba(225, 244, 244, 0.50)',
                                              }}
                                              className="rounded-md px-2 py-1 text-xs font-medium text-sorra-blue"
                                            >
                                              {fact.name}
                                            </div>
                                          );
                                        })}
                                        {restFacts.length > 0 && (
                                          <div className="flex rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800">
                                            +{restFacts.length}
                                          </div>
                                        )}
                                      </div>
                                      <div className="mt-3 w-full text-sm text-[#111928]">
                                        {storeObj && store.price && (
                                          <div className="flex items-center">
                                            <div className="mr-1 shrink-0">
                                              HK$ {store.price} @
                                            </div>
                                            {storeObj.image &&
                                            storeObj.image.url != '' ? (
                                              <div className="flex justify-start">
                                                <img
                                                  className="h-[16px] object-contain pl-1"
                                                  src={storeObj.image.url}
                                                ></img>
                                              </div>
                                            ) : (
                                              <div className="min-w-0 grow truncate text-sm font-medium text-sorra-gray-900">
                                                {storeObj.name}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {stores.length - 1 > 0 && (
                                          <div className="mt-1 text-xs font-normal text-sorra-gray-800">
                                            {`還有${p.product_stores.length - 1}間商戶亦有售`}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                )}
              </MaxWidthContainer>
            </div>
          )}
          <div
            className={
              'pt-6 lg:pt-20' +
              (sorra_popular_categories.length == 0 ? ' hidden' : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div className="text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                熱門排行榜
              </div>
              <div className="mt-5 grid grid-cols-2 gap-3 lg:grid-cols-4 lg:gap-4">
                {sorra_popular_categories.map((category) => {
                  const { id, name } = category;
                  const image = category.image
                    ? _.get(category, 'image.url')
                    : '/images/placeholders/product.png';
                  return (
                    <div
                      key={category.id}
                      className="lg:rounded-2x flex aspect-[164/80] cursor-pointer items-center gap-x-1 rounded-lg bg-[#F5F5F5] bg-opacity-50 p-1 lg:aspect-[308/130] lg:p-4"
                      onClick={() => {
                        window.location.href = `/search?product=&category=${name}&categoryID=${id}&keyword=`;
                      }}
                    >
                      <div className="aspect-square w-[72px] shrink-0 lg:w-[110px]">
                        <img
                          src={image}
                          className="h-full w-full object-cover"
                        />
                      </div>
                      <div className="min-w-0 grow text-sm font-bold lg:text-2xl">
                        <p className="line-clamp-3">{name || ''}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                社群文章
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="grid grid-cols-2 gap-x-6 gap-y-6 px-4 lg:grid-cols-4 lg:gap-y-0 lg:px-0">
              {_.filter(posts, (post) => {
                const ogImage =
                  _.get(post, 'yoast_head_json.og_image', []) || [];
                return ogImage.length > 0;
              }).map((post) => {
                // const image = _.get
                const { id, title, link, categories } = post;
                const imageUrl = _.head(
                  _.get(post, 'yoast_head_json.og_image', []) || []
                ).url;
                const categoryId = _.last(categories);
                const categoryName =
                  _.get(postCategoriesMap[categoryId], 'name', '') || '';

                return (
                  <a key={id} className="block" href={link}>
                    <img
                      className="aspect-square w-full rounded-xl object-cover"
                      src={imageUrl}
                    ></img>
                    <div className="pt-6 text-sm text-sorra-gray-800">
                      {categoryName}
                    </div>
                    <div className="line-clamp-2 pt-1 text-lg font-medium text-sorra-gray-900">
                      {title.rendered.replaceAll('&amp;', '&')}
                    </div>
                  </a>
                );
              })}
            </MaxWidthContainer>

            <MaxWidthContainer className="flex items-center justify-center p-4 pt-8">
              <a
                type="button"
                className="mb-2 mr-2 w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                href="https://www.sorra.net/blog/"
              >
                查看全部
              </a>
            </MaxWidthContainer>
          </div>

          <div className="mt-6 bg-[#F5F5F5] lg:mt-20">
            <MaxWidthContainer className="px-4 pb-[36px] pt-[36px] lg:pb-[96px] lg:pt-[96px]">
              <div className="flex items-center justify-center">
                <div className="">
                  <div
                    className="text-center text-2xl font-bold leading-[150%] text-sorra-gray-900 lg:text-5xl"
                    style={{ lineHeight: 150 + '%' }}
                  >
                    2 分鐘完成註冊 即時解鎖會員福利！
                  </div>
                  <div className="mt-[24px] lg:mt-[65px]">
                    <div className="flex w-full flex-wrap place-items-center items-center justify-center gap-x-[34px] gap-y-[40px] lg:gap-x-[94px]">
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-00.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 1 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            免費註冊
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          所有資料保密，
                          <br />
                          無滋擾聯絡。
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-01.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 2 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            完成皮膚檔案
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          於會員專區完成
                          <br />
                          「詳細皮膚檔案」
                          <br />
                          獲取額外積分。
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-02.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 3 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            留評價賺積分
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          分享你的美妝體驗，
                          <br />
                          留評價賺取積分！
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-03.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 4 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            積分好禮 Get！
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          ✦$1 Sorra 積分兌 HK$1，換取品牌好物。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[32px] text-center text-xl font-normal text-sorra-gray-900 lg:mt-[64px]">
                    <button
                      className="h-[48px] w-full rounded-[8px] bg-sorra-pink text-base font-medium text-[#fff] lg:w-[300px]"
                      onClick={() => {
                        MixpanelConfig.track('signinup_from_membership_CTA');
                        window.location.href = '/login';
                      }}
                    >
                      免費註冊
                    </button>
                  </div>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                合作優惠
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="grid grid-cols-2 gap-x-6 gap-y-6 px-4 pt-8 lg:grid-cols-4 lg:px-0">
              {/* {promotions} */}
              {promotions.map((promotion) => {
                const { id, item_codename } = promotion;
                return (
                  <a
                    key={id}
                    className="flex gap-x-1 text-sm text-sorra-gray-900"
                    href={/*element.url*/ 'rewards?tab=code'}
                  >
                    <img
                      src="/svgs/index-partnership-arrow.svg"
                      className="mb-[auto] mt-[2px]"
                    ></img>
                    {item_codename}
                  </a>
                );
              })}
              {/* {blockPromotionsState} */}
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                搜尋熱門美妝護膚分類
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="p-4 text-sm text-sorra-gray-900">
              {secondCategories.map((sc, idx) => {
                const { id, name } = sc;
                return (
                  <React.Fragment key={id}>
                    <span>{idx > 0 ? ' | ' : ''}</span>
                    <a
                      href={
                        '/search?product=&category=' +
                        name +
                        '&categoryID=' +
                        id +
                        '&keyword='
                      }
                    >
                      {name}
                    </a>
                  </React.Fragment>
                );
              })}
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div
                className="cursor-pointer items-center border-b-2 border-t-2 border-gray-100 py-6"
                onClick={() =>
                  setBottomSuggestionsExpanded(
                    bottomSuggestionsExpanded == true ? false : true
                  )
                }
              >
                <div className="flex">
                  <div className="text-2xl font-bold text-sorra-gray-900">
                    化妝護膚品推薦
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold">
                    <img
                      src="/svgs/index-section-expand.svg"
                      className={
                        bottomSuggestionsExpanded == true ? 'hidden' : ''
                      }
                    ></img>
                    <img
                      src="/svgs/index-section-collapse.svg"
                      className={
                        bottomSuggestionsExpanded == true ? '' : 'hidden'
                      }
                    ></img>
                  </div>
                </div>

                <div
                  className={
                    'pt-6' +
                    (bottomSuggestionsExpanded == true ? '' : ' hidden')
                  }
                >
                  <Markdown>{seo_recommend || ''}</Markdown>
                </div>
              </div>

              <div
                className="cursor-pointer items-center border-b-2 border-gray-100 py-6"
                onClick={() =>
                  setBottomFaqExpanded(bottomFaqExpanded == true ? false : true)
                }
              >
                <div className="flex">
                  <div className="text-2xl font-bold text-sorra-gray-900">
                    常見問題
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold">
                    <img
                      src="/svgs/index-section-expand.svg"
                      className={bottomFaqExpanded == true ? 'hidden' : ''}
                    ></img>
                    <img
                      src="/svgs/index-section-collapse.svg"
                      className={bottomFaqExpanded == true ? '' : 'hidden'}
                    ></img>
                  </div>
                </div>

                <div
                  className={
                    'pt-6' + (bottomFaqExpanded == true ? '' : ' hidden')
                  }
                >
                  <Markdown>{seo_question || ''}</Markdown>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-24"></div>

          <CommentPopup
            externalPopupShown={isCommentModalShow}
            externalProductID={null}
            externalProductBrandName={null}
            externalProductName={null}
            setExternalPopupShown={setIsCommentModalShow}
          />

          <Footer />

          <MobileTabBar props={{ highlighted: 'home' }} />
        </div>
      </main>
    </div>
  );
};

export default Home;

export async function getServerSideProps(ctx) {
  const defaultTitle =
    '護膚品好用推介/比較 | 化妝品推薦香港 | 最真實化妝護膚品評價 - Sorra';
  const defaultDescription =
    '尋找護膚品好用的推薦和比較？Sorra提供最詳實的產品評論、價格比較、成份分析和用戶意見。我們根據用戶評價和專業評測，比較各種化妝品和護膚品，幫助您做出明智的選擇。無論您需要什麼，從化妝品到護膚品，我們的平台都有您需要的資訊。立即探索，找到最適合您的產品！';

  try {
    const { data: homPageSeo } = await api.request({
      method: 'GET',
      url: `/api/seo/homePage`,
    });
    const {
      page_title: pageTitle = '',
      page_meta_description: pageDescription = '',
    } = homPageSeo;

    const title = pageTitle || defaultTitle;
    const description = pageDescription || defaultDescription;

    return {
      props: {
        seo: { title, description },
      },
    };
  } catch (e) {
    return {
      props: {
        seo: {
          title: defaultTitle,
          description: defaultDescription,
        },
      },
    };
  }
}
