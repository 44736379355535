import _ from 'lodash';
import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BLOG,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const swrFetcher = async (url) => {
  return api.get(url).then((res) => {
    return res.data;
  });
};

export default api;
