import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import '../../styles/index.css';

const IndexCommentCarouselMobile = (props) => {
  const [autoStartState, setAutoStartState] = useState(false);
  const [showArrowsState, setShowArrowsState] = useState(false);
  const [showIndicatorsState, setShowIndicatorsState] = useState(false);
  const [bannerFocusedItemState, setBannerFocusedItemState] = useState(0);

  const {
    width = '100%',
    // showArrows = true,
    // showIndicators = true,
    children,
    ...restProps
  } = props;

  function prepareCarousel() {
    setAutoStartState(false);
    setAutoStartState(true);
  }

  useEffect(() => {
    // console.log(props.props.showArrows);
    setShowArrowsState(props.props.showArrows);
    setShowIndicatorsState(props.props.showIndicators);
    prepareCarousel();
  }, [props]);

  return (
    <Carousel
      width={width}
      showArrows={showArrowsState}
      showIndicators={showIndicatorsState}
      autoPlay={autoStartState}
      showStatus={false}
      showThumbs={false}
      emulateTouch={false}
      swipeable={false}
      infiniteLoop={true}
      interval={1500}
      {...restProps}
      renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
        <>
          {/* <div class={"absolute top-0 left-0 z-20 w-[154px] h-full index-banner-overlay-left" + (bannerFocusedItemState == 0 || showArrowsState == false ? ' transpirant' : '')}>

                    </div> */}
          <button
            type="button"
            className={
              'index-banner-button-frame group absolute left-0 top-0 z-20 flex h-full w-[72px] cursor-pointer items-center justify-center px-4 focus:outline-none' +
              (bannerFocusedItemState == 0 || showArrowsState == false
                ? ' transpirant'
                : '')
            }
            onClick={clickHandler}
          >
            <div
              className={
                'index-banner-button absolute z-20 inline-flex h-10 w-10 items-center justify-center rounded-full' +
                (bannerFocusedItemState == 0 || showArrowsState == false
                  ? ' transpirant'
                  : '')
              }
            >
              <img
                className="z-20 p-[10px]"
                src="/svgs/index-horizontalScroll-arrowLeft.svg"
              ></img>
            </div>
          </button>
        </>
      )}
      renderArrowNext={(clickHandler, hasNext, labelNext) => (
        <>
          {/* <div class={"absolute top-0 right-0 z-20 w-[154px] h-full index-banner-overlay-right" + (bannerFocusedItemState + 1 >= children.length || showArrowsState == false ? ' transpirant' : '')}>

                    </div> */}
          <button
            type="button"
            className={
              'index-banner-button-frame group absolute right-0 top-0 z-20 flex h-full w-[72px] cursor-pointer items-center justify-center px-4 focus:outline-none' +
              (bannerFocusedItemState + 1 >= children.length ||
              showArrowsState == false
                ? ' transpirant'
                : '')
            }
            onClick={clickHandler}
          >
            <div
              className={
                'index-banner-button absolute z-20 inline-flex h-10 w-10 items-center justify-center rounded-full' +
                (bannerFocusedItemState + 1 >= children.length ||
                showArrowsState == false
                  ? ' transpirant'
                  : '')
              }
            >
              <img
                className="z-20 p-[10px]"
                src="/svgs/index-horizontalScroll-arrowRight.svg"
              ></img>
            </div>
          </button>
        </>
      )}
      onChange={(index) => setBannerFocusedItemState(index)}
    >
      {children}
    </Carousel>
  );
};

export default IndexCommentCarouselMobile;
