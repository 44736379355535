import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import useRWD from 'hooks/useRWD';
// import '../../styles/index.css';

const IndexBannerCarouselMobile = (props) => {
  const { isLg } = useRWD();
  const interval = isLg ? 2000 : 1500;

  // const [autoStartState, setAutoStartState] = useState(false);

  const [bannerFocusedItemState, setBannerFocusedItemState] = useState(0);

  const {
    width = '100%',
    showIndicators = true,
    children,
    showArrows = true,
    ...restProps
  } = props;

  // function prepareCarousel() {
  //   setAutoStartState(false);
  //   setAutoStartState(true);
  // }

  // useEffect(() => {
  //   setShowArrowsState(props.props.showArrows);
  //   // prepareCarousel();
  // }, [props]);

  return (
    <Carousel
      width={width}
      showArrows={showArrows}
      showIndicators={showIndicators}
      autoPlay={true}
      showStatus={false}
      showThumbs={false}
      emulateTouch={true}
      swipeable={true}
      infiniteLoop={true}
      interval={interval}
      {...restProps}
      renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
        <>
          <div
            className={
              'index-banner-button transpirant absolute inline-flex h-10 w-10 items-center justify-center rounded-full' +
              (bannerFocusedItemState == 0 || showArrows == false
                ? ' transpirant'
                : '')
            }
          >
            <img
              className="p-[10px]"
              src="/svgs/index-horizontalScroll-arrowLeft.svg"
            ></img>
          </div>
        </>
      )}
      renderArrowNext={(clickHandler, hasNext, labelNext) => (
        <>
          <div
            className={
              'index-banner-button transpirant absolute inline-flex h-full w-10 items-center justify-center rounded-full' +
              (bannerFocusedItemState + 1 >= children.length ||
              showArrows == false
                ? ' transpirant'
                : '')
            }
          >
            <img
              className="p-[10px]"
              src="/svgs/index-horizontalScroll-arrowRight.svg"
            ></img>
          </div>
        </>
      )}
      onChange={(index) => setBannerFocusedItemState(index)}
    >
      {children}
    </Carousel>
  );
};

export default IndexBannerCarouselMobile;
